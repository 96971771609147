import React, { ReactElement } from 'react'
import { useNavigate } from 'react-router-dom'

import { useAuth } from '../../context/AuthContext'

type Props = {
  children: ReactElement
}

export default function PublicRoute({ children }: Props) {
  const { authUser } = useAuth()
  const navigate = useNavigate()

  React.useEffect(() => {
    // Navigate to orders if logged in and on a public route
    // if (authUser) navigate(Routes.orders)
  }, [authUser, navigate])

  return children
}
