import { extendTheme } from '@chakra-ui/react'

export const theme = extendTheme({
  colors: {
    pinkwood: {
      50: '#f18ec8',
      100: '#ee77bc',
      200: '#eb60b1',
      300: '#e849a6',
      400: '#e5339b',
      500: '#e21c90', // Primary
      600: '#b51673',
      700: '#9e1465',
      800: '#881156',
      900: '#710e48',
    },
  },
})
