import React from 'react'
import { Navigate } from 'react-router-dom'
import { Fade } from '@chakra-ui/react'

import Routes from '../../constants/routes'
import { useAuth } from '../../context/AuthContext'

import LoadingScreen from './LoadingScreen'

export default function PrivateRoute({ children }: { children: React.ReactElement }) {
  const { loading, authUser } = useAuth()
  
  if (loading) {
    return <LoadingScreen />
  } else if (authUser) {
    return (
      // Fade is used to smoothen the abrupt loading screen that flashes on reload
      <Fade in={true} transition={{ enter: { duration: 0.9 } }}>
        {children}
      </Fade>
    )
  } else {
    return <Navigate replace to={Routes.login} />
  }
}
